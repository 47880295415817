<template>
  <div>
    <!-- 更新AI機器人 -->
    <update-modal
      v-if="selected"
      ref="updateModal"
      :open-ai-data-item="selected"
      @refetch-data="refetchTable"
    />

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>篩選器</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <!-- 創建時間 -->
          <b-col
            cols="12"
            lg="4"
            md="8"
            class="mb-xl-0 mb-1"
          >
            <label>創建時間</label>
            <b-input-group class="input-group-merge mr-1">
              <flat-pickr
                v-model="timeRange"
                class="form-control"
                :config="dateConfig2"
              />

              <b-input-group-append
                v-if="timeRange"
                is-text
              >
                <feather-icon
                  icon="XIcon"
                  class="cursor-pointer text-muted"
                  @click="timeRange = null"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              AI機器人列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>

              <!-- <div
                v-b-tooltip.hover.focus.v-secondary
                title="篩選器"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/filter.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        篩選器
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div> -->
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <!-- <label class="text-nowrap">顯示</label> -->
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
            <!-- per-page-selector d-inline-block   -->
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- d-flex flex-sm-row flex-column -->
            <!-- d-flex align-items-center justify-content-end  -->
            <!-- d-flex flex-sm-row flex-column flex-wrap justify-content-between  -->
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>

            <!-- 工具列 -->
            <!-- <div class="text-right">
                <b-button-group
                  size="sm"
                  class="mt-1 mt-sm-0"
                >
                  <b-button
                    variant="outline-primary"
                    @click="refetchTable"
                  >
                    <feather-icon icon="RotateCwIcon" />
                  </b-button>
                  <b-dropdown
                    right
                    variant="outline-primary"
                  >
                    <template #button-content>
                      <feather-icon icon="AlignJustifyIcon" />
                    </template>
                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          篩選器
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>
                  </b-dropdown>
                </b-button-group>
              </div> -->
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getOpenAIListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        class="m-0 position-relative"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: AI機器人名稱 -->
        <template #cell(role_name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap show-text"
            @click="onSubmitUpdate(data.item)"
          >
            <b-avatar
              size="40"
              :src="data.item.image"
              class="mr-50"
            />
            {{ data.item.role_name }}
          </b-link>
        </template>

        <!-- 欄位: 新增時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 更新時間 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <!-- ="data" -->
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitUpdate(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitDelete(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="刪除"
                src="/admin/images/table/delete.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BAvatar,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BCardBody, BCardHeader, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

// Component
import { useOpenAIList, useOpenAISetting } from '../useOpenAI'
import UpdateModal from './OpenAIListUpdateModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'
import { blankSocketData } from '@/libs/socket-client'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BImg,
    BLink,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    // BButtonGroup,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    flatPickr,
    // AddModal,
    // EditModal,
    // AuthModal,
    UpdateModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
    isSocketInit() {
      return this.$store.state.app.isSocketInitialized
    },
  },
  watch: {
    isSocketInit(newValue) {
      if (newValue) {
        this.resetSocketConnect()
      }
    },
  },
  mounted() {
    this.initSocketFunction()
    this.timer = setInterval(this.onlineTime, 1000)
    this.$store.dispatch('app/isSocketFunctionExist', 'joinOpenAI')
      .then(state => {
        if (state) window.socket.joinOpenAI()
      })
  },
  beforeDestroy() {
    const { blankOpenAIRoom } = blankSocketData()
    window.web.openAI = { ...blankOpenAIRoom }
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)新增/編輯管理員
    onSubmitUpdate(item) {
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs.updateModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (刪除)管理員
    onSubmitDelete(item) {
      this.useSwalAlertWarning('刪除AI機器人', `你確定要永久刪除AI機器人 ${item.role_name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setOpenAIDelete({
              openAI_id: item.id,
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', 'AI機器人已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (初始化)Socket
    initSocketFunction() {
      const windowFunction = window.web
      windowFunction.openAI = {
        refreshOpenAIRoleList: () => {
          this.refetchData()
        },
      }
      if (this.isSocketInit) store.commit('app/SET_SOCKET_INIT', false)
    },

    // (重建)Socket連線
    resetSocketConnect() {
      this.$store.dispatch('app/isSocketFunctionExist', 'joinOpenAI')
        .then(state => {
          if (state) window.socket.joinOpenAI()
        })
      store.commit('app/SET_SOCKET_INIT', false)
    },
  },
  setup() {
    // 註冊模組
    const USER_ADMIN_STORE_MODULE_NAME = 'admin-openAI'

    if (!store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.registerModule(USER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(USER_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setOpenAIDelete,
      getOpenAIListData,
    } = useOpenAIList()

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useOpenAISetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      useAlertToast,
      dateConfig2,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setOpenAIDelete,
      getOpenAIListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
