<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ openAiDataItem.id ? '編輯使用者' : '新增使用者' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-1 my-md-2">
          <b-col
            md="4"
            cols="12"
          >
            <div class="ai-image-area">
              <b-avatar
                size="80"
                :src="openAIData.image"
                class="cursor-pointer"
              />
              <div
                v-if="openAIData.image"
                class="clear-icon"
                @click="setImageClear"
              >
                <b-img
                  width="16"
                  src="/admin/images/table/cancel.svg"
                />
              </div>
              <div
                class="edit-mask"
                @click="getImage"
              >
                <div
                  class="edit-icon"
                >
                  <b-img
                    width="20"
                    src="/admin/images/table/edit.svg"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            md="6"
            cols="12"
            class="mt-1"
          >
            <!-- AI機器人名稱 -->
            <validation-provider
              #default="{ errors }"
              name="AI機器人名稱"
              rules="required"
            >
              <b-form-group label-for="role_name">
                <template #label>
                  <label class="mb-0">
                    AI機器人名稱
                    <span class="text-danger">*</span>
                  </label>
                </template>
                <b-form-input
                  id="name"
                  v-model="openAIData.role_name"
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="openAiDataItem.role_name ? openAiDataItem.role_name : '請輸入AI機器人名稱'"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>

        <!-- 角色設定 -->
        <validation-provider
          #default="{ errors }"
          name="AI角色設定"
          rules="required"
        >
          <b-form-group label-for="setting">
            <template #label>
              <label class="mb-0">
                AI角色設定
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-textarea
              id="setting"
              v-model="openAIData.setting"
              :state="openAiDataItem.setting.length <= maxChar ? null : false"
              rows="5"
              trim
              :placeholder="openAiDataItem.setting ? openAiDataItem.setting : '請輸入AI角色設定'"
            />

            <small
              class="textarea-counter-value float-right"
              :class="openAiDataItem.setting.length > maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ openAiDataItem.setting.length }}</span> / {{ maxChar }}
            </small>

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

    <!-- 變更頭像 -->
    <b-modal
      id="image-update-modal"
      no-close-on-backdrop
      ok-title="確認"
      centered
      size="sm"
      header-bg-variant="primary"
      ok-only
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          變更AI機器人頭像
        </h4>
      </template>

      <div class="d-flex justify-content-center align-items-center">
        <div v-if="!isImageBusy">
          <div
            class="my-2 d-flex justify-content-center"
          >
            <div class="site-modal-images">
              <div
                v-if="openAIImage.src"
                class="site-modal-tool"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="16"
                  class="site-modal-tool-icon"
                  @click="submitResetImage(index)"
                />
              </div>
              <b-avatar
                :src="openAIImage.src"
                fluid
                size="100"
                class="profile-modal-image"
              />
            </div>
          </div>

          <input
            ref="imageInput"
            type="file"
            hidden
            @input="changeImage($event)"
          >
        </div>
        <div
          v-else
          class="text-center p-2"
        >
          <b-img
            src="/admin/images/common/loading-2.png"
            rounded
            height="50"
            width="50"
          />
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="flat-primary"
            @click="$refs.imageInput.click()"
          >
            上傳
          </b-button>
          <b-button
            variant="flat-primary"
            :disabled="isBusy || !openAIImage.src"
            @click="handleImageOk"
          >
            <span v-if="!isBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>

    </b-modal>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BRow, BCol, BAvatar, BImg,
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea, VBTooltip,
  // BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { useOpenAIList, useOpenAISetting } from '../useOpenAI'
import { useAlert, useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BForm,
    // BFormGroup,
    BAvatar,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BFormSpinbutton,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    openAiDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      maxChar: 255,
      passwordFieldType: 'password',
      isImageBusy: false,
      openAIImage: {
        src: '',
        file: null,
      },
    }
  },
  computed: {
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetOpenAIData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        role_name: 'AI機器人名稱',
        setting: 'AI角色設定',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.openAIData[Object.keys(requiredFields)[i]] === null || this.openAIData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.openAiDataItem.id) {
        this.setOpenAIUpdate({
          openAI_id: this.openAiDataItem.id,
          data: {
            ...this.openAIData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetOpenAIData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setOpenAICreate({
          ...this.openAIData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetOpenAIData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankOpenAIData, this.openAiDataItem),
      }
      this.openAIData = resolveData
      this.$bvModal.show('update-modal')
    },

    // ----------------------------------------------------------------
    // ------------------------頭像------------------------------------
    // ----------------------------------------------------------------
    // (獲取)頭像
    getImage() {
      this.submitResetImage()
      this.openAIImage = {
        src: this.openAIData.image,
        file: null,
      }
      this.$bvModal.show('image-update-modal')
    },
    // (獲取)頭像
    setImageClear() {
      this.useSwalAlertWarning('重設大頭貼', '你確定要移除目前的大頭貼嗎?')
        .then(result => {
          if (result.value) {
            this.setOpenAIImageDelete({
              openAI_id: this.openAIData.id,
            })
              .then(() => {
                this.$emit('refetch-data', this.openAIData.id)
                this.openAIData.image = null
                this.useSwalAlertCenter(true, '刪除成功!', '客戶大頭貼已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
    // (變更)大頭貼
    changeImage(e) {
      const { files } = e.target
      const [file] = files
      const judgeImageOption = [
        'image/png',
        'image/jpeg',
      ]
      if (!judgeImageOption.includes(file.type)) {
        this.useSwalAlertWarning('請上傳圖片檔', '')
        return
      }
      const objectURL = window.URL.createObjectURL(file)
      this.openAIImage = {
        src: objectURL,
        file,
      }
    },
    // (觸發)刪除大頭貼
    submitResetImage() {
      this.openAIImage = {
        src: '',
        file: null,
      }
    },
    // (確認)彈窗
    handleImageOk() {
      if (!this.openAIImage.file) {
        return
      }
      const formData = new FormData()
      formData.append('data', this.openAIImage.file)
      this.isImageBusy = true
      this.setOpenAIImageUpdate({
        openAI_id: this.openAIData.id,
        data: formData,
      })
        .then(response => {
          const { data } = response.data
          const resolveData = {
            ...this.syncObject(this.blankOpenAIData, data),
          }
          this.openAIData = resolveData
          this.isImageBusy = false
          this.submitResetImage()
          this.$bvModal.hide('image-update-modal')
          this.$emit('refetch-data', this.openAIData.id)
          this.useSwalAlertCenter(response.data.success, response.data.message, `${data.role_name}的大頭貼已更新`)
        })
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setOpenAICreate,
      setOpenAIUpdate,
      setOpenAIImageUpdate,
      setOpenAIImageDelete,
    } = useOpenAIList()

    const {
      syncObject,
    } = useOpenAISetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankOpenAIData = {
      id: null,
      image: null,
      role_name: null,
      setting: null,
    }

    const openAIData = ref(JSON.parse(JSON.stringify(blankOpenAIData)))

    const resetOpenAIData = () => {
      openAIData.value = JSON.parse(JSON.stringify(blankOpenAIData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetOpenAIData)

    return {
      isBusy,

      openAIData,
      blankOpenAIData,
      setOpenAICreate,
      setOpenAIUpdate,
      setOpenAIImageUpdate,
      setOpenAIImageDelete,

      resetOpenAIData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
.site-modal-images {
  .site-modal-tool {
    position: absolute;
    padding: 5px 10px;
    background-color: rgba(51, 51, 51, 0.59);
    border-radius: 5px;
    color: white;
    top: 5%;
    right: 8%;
    .site-modal-tool-icon {
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
.ai-image-area {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .edit-mask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s;
    cursor: pointer;
    opacity: 0;
    &:hover {
      opacity: 1;
      background-color:#00000075;
    }
  }
  .clear-icon {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    &:hover {
      background-color:#7e7e7e81;
    }
  }
}
</style>
